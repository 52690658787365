<template>
  <div class="mt-3">
    <div
      class="compliance--references"
      v-for="(reference, index) in workerReferences"
      :key="index"
    >
      <div class="d-flex justify-space-between">
        <h4 class="pb-2">Reference {{ index + 1 }}</h4>
        <v-btn
          icon
          text
          x-small
          @click="handleRemoveReference(reference.id)"
          v-if="index > 0"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
      <ReferenceForm
        :data="reference"
        :showValidationErrors="showValidationErrors"
        @onChange="handleUpdateReference"
      />
    </div>
    <PrimaryButton fullWidth light @click.native="handleAddReference">
      Add References +
    </PrimaryButton>
  </div>
</template>

<script>
import ReferenceForm from "@/components/workers/common/ReferenceForm";
import PrimaryButton from "@/components/common/Button/PrimaryButton";
import { remove, map } from "lodash";
import generateId from "uuid/v4";

export default {
  props: {
    /**
     *  Data object
     */
    references: {
      type: Array,
      required: true
    },
    showValidationErrors: Boolean
  },
  components: { ReferenceForm, PrimaryButton },
  computed: {
    workerReferences: {
      get() {
        return this.references;
      },
      set(value) {
        this.$emit("onChange", value);
      }
    }
  },
  methods: {
    handleAddReference() {
      const newReference = {
        id: generateId(),
        referenceTypeName: null,
        periodStartDate: null,
        periodEndDate: null,
        referenceReceivedDate: null,
        isNew: true
      };

      this.workerReferences.push(newReference);
      this.$forceUpdate();
    },
    handleRemoveReference(id) {
      this.workerReferences = map(this.workerReferences, reference => {
        if (reference.id === id) {
          reference.toBeDeleted = true;
        }
        return reference;
      });
      remove(this.workerReferences, reference => reference.id === id);
      this.$forceUpdate();
    },
    handleUpdateReference(updatedReference) {
      this.workerReferences = map(this.workerReferences, reference => {
        if (reference.id === updatedReference.id) {
          reference = updatedReference;
        }

        return reference;
      });
    }
  }
};
</script>

<style lang="scss" scoped></style>
