var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('FileUpload',{attrs:{"uploadingUrl":_vm.getAddFileUrl,"fileTypes":[
      '.pdf',
      '.jpeg',
      '.jpg',
      '.png',
      '.doc',
      '.docx',
      '.xlsx',
      '.ppt'
    ],"files":_vm.files,"id":_vm.complianceId,"isLoading":_vm.isLoading,"showValidationErrors":_vm.showValidationErrors,"customActions":"","isMultiple":"","disablePreview":""},on:{"removeFile":_vm.onRemoveFile,"selectFile":_vm.selectFile,"onUploadSuccess":_vm.updateFiles}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }