<template>
  <div>
    <v-form ref="form">
      <h1>Income Type</h1>
      <RadioButton
        :options="incomeTypes"
        :selectedValue="currentWorker.incomeType"
        :disabled="readonly('workers.edit.income-type')"
        @select="handleIncomeChange"
      />
      <div v-if="showIntermediary">
        <h3 class="pb-3 mt-4">Intermediary Company</h3>
        <div class="flex-gap-15">
          <ValidationProvider
            v-slot="{ errors }"
            name="companyName"
            rules="required"
          >
            <v-text-field
              @change="handleChange"
              v-model="currentWorker.company.name"
              :readonly="readonly('workers.edit-intermediary-organisation')"
              placeholder=" "
              label="Company Name"
              :error-messages="getErrorMessages(errors)"
              required
            />
          </ValidationProvider>
          <ValidationProvider
            v-slot="{ errors }"
            name="registrationNumber"
            rules="required"
          >
            <v-text-field
              @change="handleChange"
              v-model="currentWorker.company.companyRegistrationNumber"
              :readonly="readonly('workers.edit-intermediary-organisation')"
              label="Company Registration Number"
              placeholder=" "
              :error-messages="getErrorMessages(errors)"
              required
            />
          </ValidationProvider>
          <ValidationProvider
            v-slot="{ errors }"
            name="line1"
            rules="required|max:60"
          >
            <v-text-field
              @change="handleChange"
              v-model="currentWorker.companyAddress.line1"
              :readonly="readonly('workers.edit-intermediary-organisation')"
              label="Address Line 1"
              placeholder=" "
              required
              :error-messages="getErrorMessages(errors)"
            />
          </ValidationProvider>
          <ValidationProvider v-slot="{ errors }" name="line2" rules="max:60">
            <v-text-field
              @change="handleChange"
              v-model="currentWorker.companyAddress.line2"
              :readonly="readonly('workers.edit-intermediary-organisation')"
              :error-messages="getErrorMessages(errors)"
              label="Address Line 2"
              placeholder=" "
              required
            />
          </ValidationProvider>
          <div class="item">
            <ValidationProvider
              v-slot="{ errors }"
              name="line3"
              rules="required|max:60"
            >
              <v-text-field
                @change="handleChange"
                v-model="currentWorker.companyAddress.line3"
                :readonly="readonly('workers.edit-intermediary-organisation')"
                placeholder=" "
                label="Town/City"
                :error-messages="getErrorMessages(errors)"
                required
              />
            </ValidationProvider>
          </div>
          <div class="item">
            <ValidationProvider
              v-slot="{ errors }"
              name="postCode"
              rules="required|max:10"
            >
              <v-text-field
                @change="handleChange"
                v-model="currentWorker.companyAddress.postCode"
                :readonly="readonly('workers.edit-intermediary-organisation')"
                label="Postcode"
                placeholder=" "
                :error-messages="getErrorMessages(errors)"
                required
              />
            </ValidationProvider>
          </div>
          <ValidationProvider
            v-slot="{ errors }"
            name="country"
            rules="required"
          >
            <Select
              label="Country"
              @change="handleChange"
              v-model="currentWorker.companyAddress.country"
              :items="countries"
              :disabled="readonly('workers.edit-intermediary-organisation')"
              item-text="label"
              item-value="id"
              :error-messages="getErrorMessages(errors)"
              autocomplete
              required
            />
          </ValidationProvider>
        </div>
      </div>
    </v-form>
  </div>
</template>

<script>
import RadioButton from "@/components/common/RadioButton";
import { DATA_NAMESPACE } from "@/store/modules/data";
import { createNamespacedHelpers } from "vuex";
import { FETCH_INCOME_TYPES } from "@/store/modules/data/actions";
import {
  GET_INCOME_TYPES_OPTIONS,
  GET_COUNTRIES
} from "@/store/modules/data/getters";
import Select from "@/components/common/Select";
import { ValidationProvider } from "vee-validate";

const { mapActions, mapGetters } = createNamespacedHelpers(DATA_NAMESPACE);

export default {
  props: {
    /**
     *  Data object
     */
    currentWorker: {
      type: Object,
      required: true
    },
    permissions: Object,
    isUpdate: Boolean,
    showValidationErrors: Boolean
  },
  components: {
    RadioButton,
    Select,
    ValidationProvider
  },
  created() {
    this.fetchIncomeTypes();
  },
  data() {
    return {
      incomeType: this.currentWorker.incomeType
    };
  },
  computed: {
    ...mapGetters({
      incomeTypes: GET_INCOME_TYPES_OPTIONS,
      countries: GET_COUNTRIES
    }),
    showIntermediary() {
      return this.incomeType !== "PAYE";
    }
  },
  methods: {
    ...mapActions({
      fetchIncomeTypes: FETCH_INCOME_TYPES
    }),
    handleChange() {
      this.$emit("onChange", this.currentWorker);
    },
    handleIncomeChange(value) {
      this.currentWorker.incomeTypeName = value;
      this.currentWorker.incomeType = value;
      this.incomeType = value;
      this.handleChange();
    },
    isPermitted(permission) {
      return this.permissions[permission];
    },
    readonly(permission) {
      return this.isUpdate && !this.isPermitted(permission);
    },
    getErrorMessages(errors) {
      return this.showValidationErrors ? errors : "";
    }
  }
};
</script>

<style lang="scss" scoped></style>
