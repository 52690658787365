<template>
  <ValidationObserver ref="validation">
    <ValidationProvider
      v-slot="{ errors }"
      :name="validatorName"
      rules="required"
    >
      <v-text-field
        v-model="compliance.documentReferenceNumber"
        :error-messages="getErrorMessages(errors)"
        @input="handleChange"
        :label="label"
        :placeholder="placeholder"
        class="mb-3"
      />
    </ValidationProvider>
  </ValidationObserver>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";

export default {
  props: {
    /**
     *  Data object
     */
    data: {
      type: Object,
      required: true
    },
    label: String,
    showValidationErrors: Boolean,
    validatorName: String,
    placeholder: {
      type: String,
      required: false,
      default: "Enter Number"
    }
  },
  components: {
    ValidationProvider,
    ValidationObserver
  },
  data() {
    return {
      compliance: this.data
    };
  },
  methods: {
    handleChange() {
      this.$emit("onChange", this.compliance);
    },
    getErrorMessages(errors) {
      return this.showValidationErrors ? errors : "";
    }
  }
};
</script>

<style lang="scss" scoped></style>
