<template>
  <div class="flex-gap-15 mb-3">
    <ValidationProvider v-slot="{ errors }" name="reference" rules="required">
      <Select
        label="Reference Type"
        placeholder="Select"
        :error-messages="showValidationErrors ? errors : ''"
        v-model="reference.referenceTypeName"
        @change="handleChange"
        :items="types"
        autocomplete
      />
    </ValidationProvider>
    <ValidationProvider
      v-slot="{ errors }"
      name="reference.periodStartDate && reference.periodEndDate"
      rules="required|validDate|validDateRange"
    >
      <v-text-field
        type="text"
        placeholder="dd/mm/yyyy-dd/mm/yyyy"
        @change="setPeriodCovered"
        v-mask="'##/##/####-##/##/####'"
        v-model="dateText"
        :error-messages="getErrorMessages(errors)"
        label="Period Covered"
      />
    </ValidationProvider>
    <DatePicker
      @onChange="setReceivedOn"
      label="Reference Received On"
      validateName="received"
      :maxDate="allowedDate"
      :input-value="reference.referenceReceivedDate"
      :showValidationErrors="showValidationErrors"
      required
      isDark
    />
  </div>
</template>

<script>
import Select from "@/components/common/Select";
import DatePicker from "@/components/common/DatePicker";
import { REFERENCE_TYPES } from "@/constants/workers";
import { ValidationProvider } from "vee-validate";
import Vue from "vue";
import moment from "moment";
import VueMask from "v-mask";
import {
  formatDateText,
  isValidDateRange,
  splitDateRange,
  formatDate
} from "@/utils/time";

Vue.use(VueMask);

export default {
  props: {
    /**
     *  Data object
     */
    data: {
      type: Object,
      required: true
    },
    showValidationErrors: Boolean
  },
  components: {
    Select,
    DatePicker,
    ValidationProvider
  },
  mounted() {
    if (this.reference.periodStartDate || this.reference.periodEndDate) {
      this.startDate = formatDateText(this.reference.periodStartDate);
      this.endDate = formatDateText(this.reference.periodEndDate);
      this.dateText = `${this.startDate}-${this.endDate}`;
    }
    if (this.reference.referenceReceivedDate) {
      this.reference.referenceReceivedDate = formatDate({
        date: this.reference.referenceReceivedDate
      });
    }
  },
  computed: {
    allowedDate() {
      return moment().format("YYYY-MM-DD");
    }
  },
  data() {
    return {
      types: REFERENCE_TYPES,
      reference: this.data,
      startDate: this.data.periodStartDate,
      endDate: this.data.periodEndDate,
      dateText: `${this.startDate}-${this.endDate}`
    };
  },
  methods: {
    handleChange() {
      this.$emit("onChange", this.reference);
    },
    getErrorMessages(errors) {
      return this.showValidationErrors ? errors : "";
    },
    setPeriodCovered(dates) {
      const { dateCovered, startDate, endDate } = splitDateRange(dates);

      if (isValidDateRange(dateCovered)) {
        this.reference.periodStartDate = startDate;
        this.reference.periodEndDate = endDate;
      }
      this.handleChange();
    },
    setReceivedOn(date) {
      this.reference.referenceReceivedDate = formatDate({ date });
      this.handleChange();
    }
  }
};
</script>

<style lang="scss" scoped></style>
