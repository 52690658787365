<template>
  <div class="flex-gap-15 mb-3">
    <ValidationProvider
      v-slot="{ errors }"
      name="document"
      :rules="`${ruleRequired}`"
    >
      <Select
        label="Document Type"
        placeholder="Select"
        :items="rightToWorkTypes"
        :error-messages="getErrorMessages(errors)"
        v-model="compliance.documentTypeName"
        @change="handleChange"
        autocomplete
      />
    </ValidationProvider>
    <ValidationProvider
      v-slot="{ errors }"
      name="dateText"
      :rules="
        `${ruleRequired ? ruleRequired + '|' : ''}validDate|validDateRange`
      "
    >
      <v-text-field
        type="text"
        placeholder="dd/mm/yyyy-dd/mm/yyyy"
        @change="updateDates"
        v-mask="'##/##/####-##/##/####'"
        format="YYYY-MM-DD"
        v-model="dateText"
        :error-messages="getErrorMessages(errors)"
        label="Document Valid From - Valid Until"
      />
    </ValidationProvider>
  </div>
</template>

<script>
import Select from "@/components/common/Select";
import { ValidationProvider } from "vee-validate";
import { RIGHT_TO_WORK_DOCUMENT_TYPES } from "@/constants/workers";
import Vue from "vue";
import VueMask from "v-mask";
import { formatDateText, isValidDateRange, splitDateRange } from "@/utils/time";

Vue.use(VueMask);

export default {
  props: {
    /**
     *  Data object
     */
    data: {
      type: Object,
      required: true
    },
    showValidationErrors: Boolean
  },
  components: {
    Select,
    ValidationProvider
  },
  data() {
    return {
      rightToWorkTypes: RIGHT_TO_WORK_DOCUMENT_TYPES,
      compliance: this.data,
      startDate: this.data.documentValidFromDate,
      endDate: this.data.documentValidToDate,
      dateText: `${this.startDate}-${this.endDate}`,
      ruleRequired:
        process.env.VUE_APP_HIDE_COMPLIANCE_FOR_CSP !== "true" ? "required" : ""
    };
  },
  mounted() {
    if (
      this.compliance.documentValidFromDate &&
      this.compliance.documentValidToDate
    ) {
      this.startDate = formatDateText(this.compliance.documentValidFromDate);
      this.endDate = formatDateText(this.compliance.documentValidToDate);
      this.dateText = `${this.startDate}-${this.endDate}`;
    }
  },
  methods: {
    handleChange() {
      this.$emit("onChange", this.compliance);
    },
    getErrorMessages(errors) {
      return this.showValidationErrors ? errors : "";
    },
    updateDates(dates) {
      const { dateCovered, startDate, endDate } = splitDateRange(dates);

      if (isValidDateRange(dateCovered)) {
        this.compliance.documentValidFromDate = startDate;
        this.compliance.documentValidToDate = endDate;
      }
      this.handleChange();
    }
  }
};
</script>

<style lang="scss" scoped></style>
