export const validatonScrollMixin = {
  methods: {
    scrollToErrorMessage() {
      const el = document.querySelector(".error--text");
      if (el) {
        el.scrollIntoView({ behavior: "smooth" });
      }
    }
  }
};
