<template>
  <div>
    <v-form ref="form">
      <h1 class="pb-3">Basic Details</h1>
      <div class="flex-gap-15">
        <ValidationProvider
          v-slot="{ errors }"
          name="firstName"
          rules="required"
        >
          <v-text-field
            @change="handleChange"
            v-model="currentWorker.firstName"
            label="First Name"
            required
            validate-on-blur
            :error-messages="getErrorMessages(errors)"
            :readonly="readonly('workers.edit.name')"
          />
        </ValidationProvider>
        <ValidationProvider
          v-slot="{ errors }"
          name="lastName"
          rules="required"
        >
          <v-text-field
            @change="handleChange"
            v-model="currentWorker.lastName"
            label="Last Name"
            required
            validate-on-blur
            :error-messages="getErrorMessages(errors)"
            :readonly="readonly('workers.edit.name')"
          />
        </ValidationProvider>
        <ValidationProvider
          v-slot="{ errors }"
          name="email"
          rules="required|email"
        >
          <v-text-field
            @change="handleChange"
            @blur="checkEmail"
            v-model="currentWorker.email"
            type="email"
            label="Email Address"
            required
            validate-on-blur
            :error-messages="getErrorMessages(errors)"
            :readonly="readonly('workers.edit.email')"
            :disabled="isUpdate"
          />
        </ValidationProvider>
        <ValidationProvider v-slot="{ errors }" name="phone" rules="required">
          <v-text-field
            @change="handleChange"
            label="Phone Number"
            :error-messages="getErrorMessages(errors)"
            :readonly="readonly('workers.edit.phone-number')"
            v-model="currentWorker.phone"
          />
        </ValidationProvider>
        <ValidationProvider
          v-slot="{ errors }"
          name="dob"
          rules="required|validDate"
        >
          <v-text-field
            type="text"
            label="Date of Birth"
            placeholder="dd/mm/yyyy"
            @input="handleDob"
            v-mask="'##/##/####'"
            format="YYYY-MM-DD"
            :readonly="readonly('workers.edit.dob')"
            v-model="dob"
            :error-messages="getErrorMessages(errors)"
          />
        </ValidationProvider>
      </div>
      <h3 class="pt-3 pb-2">Address</h3>
      <div class="flex-gap-15">
        <ValidationProvider
          v-slot="{ errors }"
          name="line1"
          rules="required|max:60"
        >
          <v-text-field
            @change="handleChange"
            v-model="currentWorker.address.line1"
            label="Address Line 1"
            :readonly="readonly('workers.edit.address')"
            :error-messages="getErrorMessages(errors)"
          />
        </ValidationProvider>
        <ValidationProvider v-slot="{ errors }" name="line2" rules="max:60">
          <v-text-field
            @change="handleChange"
            v-model="currentWorker.address.line2"
            label="Address Line 2"
            :readonly="readonly('workers.edit.address')"
            :error-messages="getErrorMessages(errors)"
          />
        </ValidationProvider>
        <div class="item">
          <ValidationProvider
            v-slot="{ errors }"
            name="line3"
            rules="required|max:60"
          >
            <v-text-field
              @change="handleChange"
              v-model="currentWorker.address.line3"
              label="Town/City"
              :readonly="readonly('workers.edit.address')"
              :error-messages="getErrorMessages(errors)"
            />
          </ValidationProvider>
        </div>
        <div class="item">
          <ValidationProvider
            v-slot="{ errors }"
            name="postCode"
            rules="required|max:10"
          >
            <v-text-field
              @change="handleChange"
              v-model="currentWorker.address.postCode"
              label="Postcode"
              :readonly="readonly('workers.edit.address')"
              :error-messages="getErrorMessages(errors)"
            />
          </ValidationProvider>
        </div>
        <ValidationProvider v-slot="{ errors }" name="country" rules="required">
          <Select
            v-model="currentWorker.address.country"
            label="Country"
            :items="countries"
            item-text="label"
            item-value="id"
            :disabled="readonly('workers.edit.address')"
            :error-messages="getErrorMessages(errors)"
            autocomplete
          />
        </ValidationProvider>
      </div>
    </v-form>
  </div>
</template>

<script>
import { DATA_NAMESPACE } from "@/store/modules/data";
import { USERS_NAMESPACE } from "@/store/modules/users";
import { CHECK_EXISTING_EMAIL } from "@/store/modules/users/actions";
import { createNamespacedHelpers } from "vuex";
import { FETCH_COUNTRIES } from "@/store/modules/data/actions";
import { GET_COUNTRIES } from "@/store/modules/data/getters";
import Select from "@/components/common/Select";
import { ValidationProvider } from "vee-validate";
import Vue from "vue";
import VueMask from "v-mask";
import moment from "moment";
import { emailExistingError } from "@/utils/users";

const { mapGetters, mapActions } = createNamespacedHelpers(DATA_NAMESPACE);
const { mapActions: userMapActions } = createNamespacedHelpers(USERS_NAMESPACE);
Vue.use(VueMask);

export default {
  name: "BasicDetails",
  props: {
    /**
     *  Data object
     */
    currentWorker: {
      type: Object,
      required: true
    },
    showValidationErrors: Boolean,
    permissions: Object,
    isUpdate: Boolean
  },
  data() {
    return {
      dob: this.currentWorker.dob
        ? moment(this.currentWorker.dob).format("DD/MM/YYYY")
        : ""
    };
  },
  components: {
    Select,
    ValidationProvider
  },
  created() {
    this.fetchCountries();
    this.dob = this.currentWorker.dob
      ? moment(this.currentWorker.dob).format("DD/MM/YYYY")
      : "";
    this.handleChange();
  },
  computed: {
    ...mapGetters({
      countries: GET_COUNTRIES
    })
  },
  methods: {
    ...mapActions({
      fetchCountries: FETCH_COUNTRIES
    }),
    ...userMapActions({ checkExistingEmail: CHECK_EXISTING_EMAIL }),
    handleChange() {
      this.$emit("onChange", this.currentWorker);
    },
    getErrorMessages(errors) {
      return this.showValidationErrors ? errors : "";
    },
    handleDob(date) {
      this.currentWorker.dob = moment(date, "DD/MM/YYYY").format("YYYY-MM-DD");
      this.dob = date;
      this.handleChange();
    },
    isPermitted(permission) {
      return this.permissions[permission];
    },
    readonly(permission) {
      return this.isUpdate && !this.isPermitted(permission);
    },
    async checkEmail() {
      const { user, isExistingEmail } = await this.checkExistingEmail(
        this.currentWorker.email
      );
      if (isExistingEmail && user.id !== this.currentWorker.id) {
        emailExistingError(this.currentWorker.email);
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
