<template>
  <div>
    <h1 class="pb-3">Diversity</h1>
    <div class="flex-gap-15">
      <ValidationProvider v-slot="{ errors }" name="gender" rules="required">
        <Select
          @change="handleChange"
          v-model="currentWorker.gender"
          :items="genders"
          :disabled="readonly('workers.edit.gender')"
          :error-messages="showValidationErrors ? errors : ''"
          label="Gender"
        >
          <template v-slot:item="data">
            {{ data.item | capitalize }}
          </template>
          <template v-slot:selection="{ data }">
            {{ data.item | capitalize }}
          </template>
        </Select>
      </ValidationProvider>
      <Select
        v-model="currentWorker.ethnicGroupId"
        @change="handleChange"
        :items="originOptions"
        label="Ethnic Origin"
        item-text="label"
        item-value="id"
        :disabled="readonly('workers.edit.ethnic-origin')"
        autocomplete
      />
      <ValidationProvider v-slot="{ errors }" rules="required">
        <Select
          :error-messages="showValidationErrors ? errors : ''"
          v-model="currentWorker.countryOfBirth"
          @change="handleChange"
          :items="countries"
          item-text="label"
          item-value="id"
          label="Country of Birth"
          :disabled="readonly('workers.edit.country-of-birth')"
          autocomplete
        />
      </ValidationProvider>
      <p>Does the Disability Discrimination Act apply</p>
      <RadioButton
        @select="handleRadioChange"
        :selected-value="currentWorker.dda"
        :options="ddaOptions"
        showGroupInRow
        :disabled="readonly('workers.edit.dda')"
      />
    </div>
  </div>
</template>

<script>
import RadioButton from "@/components/common/RadioButton";
import Select from "@/components/common/Select";
import { DATA_NAMESPACE } from "@/store/modules/data";
import { GET_COUNTRIES } from "@/store/modules/data/getters";
import { createNamespacedHelpers } from "vuex";
import { BOOLEAN_OPTIONS, ETHNIC_GROUPS, GENDERS } from "@/constants/workers";
import { ValidationProvider } from "vee-validate";

const { mapGetters } = createNamespacedHelpers(DATA_NAMESPACE);

export default {
  props: {
    /**
     *  Data object
     */
    currentWorker: {
      type: Object,
      required: true
    },
    permissions: Object,
    isUpdate: Boolean,
    showValidationErrors: Boolean
  },
  components: {
    RadioButton,
    Select,
    ValidationProvider
  },
  data() {
    return {
      ddaOptions: BOOLEAN_OPTIONS,
      originOptions: ETHNIC_GROUPS,
      genders: GENDERS
    };
  },
  computed: {
    ...mapGetters({
      countries: GET_COUNTRIES
    })
  },
  methods: {
    handleChange() {
      this.$emit("onChange", this.currentWorker);
    },
    handleRadioChange(value) {
      this.currentWorker.dda = value;
      this.handleChange();
    },
    isPermitted(permission) {
      return this.permissions[permission];
    },
    readonly(permission) {
      return this.isUpdate && !this.isPermitted(permission);
    }
  }
};
</script>

<style lang="scss" scoped></style>
