<template>
  <div>
    <h1 class="pb-3">Compliance Checks Supporting Document</h1>
    <FileUpload
      @onUploadSuccess="handleUploadSuccess"
      @removeFile="onRemoveFile"
      @unselectFile="onRemoveFile"
      @show="onShow"
      @selectFile="selectFile"
      :uploadingUrl="getAddFileUrl"
      :fileTypes="[
        '.pdf',
        '.jpeg',
        '.jpg',
        '.png',
        '.doc',
        '.docx',
        '.xlsx',
        '.ppt'
      ]"
      :files="files"
      :id="documentId"
      :isLoading="isLoading"
      :disableAutoProceed="!isFileUploaded"
      customActions
    />
    <PDFViewFull :data="fileDialog" />
  </div>
</template>

<script>
import FileUpload from "@/components/common/FileUpload";
import APIService from "@/services/APIService";
import { DELETE_WORKER_COMPLIANCE_DOC } from "@/store/modules/workers/action-types";
import { mapActions, mapState } from "vuex";
import PDFViewFull from "@/components/common/PDFViewFull";
import { getToken } from "@/services/utils";

export default {
  props: {
    /**
     *  Data object
     */
    currentWorker: {
      type: Object,
      required: true
    },
    permissions: Object,
    isUpdate: Boolean
  },
  components: {
    FileUpload,
    PDFViewFull
  },
  data() {
    return {
      isLoading: false,
      files: this.currentWorker.complianceSupportingFile
        ? [this.currentWorker.complianceSupportingFile]
        : [],
      fileDialog: {
        title: "",
        dialog: false,
        src: ""
      },
      isFileUploaded: false
    };
  },
  computed: {
    ...mapState("workers", {
      currentWorkerId: state => state.currentWorkerId
    }),
    documentId() {
      return `compliance_doc${this.currentWorkerId}`;
    },
    getAddFileUrl() {
      const host = APIService.host;
      return `${host}/users/${this.currentWorkerId}/compliance-document`;
    }
  },
  methods: {
    ...mapActions("workers", {
      removeFile: DELETE_WORKER_COMPLIANCE_DOC
    }),
    selectFile({ uppyInstance, file }) {
      uppyInstance.setFileState(file.id, {
        xhrUpload: { endpoint: this.getAddFileUrl }
      });
      uppyInstance.upload(this.currentWorkerId).then(() => {
        this.isFileUploaded = true;
        this.$forceUpdate();
      });
    },
    async onRemoveFile() {
      this.isLoading = true;
      try {
        await this.removeFile(this.currentWorkerId);
        this.fileRemoved();
        this.isFileUploaded = false;
      } finally {
        this.isLoading = false;
      }
    },
    fileRemoved() {
      this.files = [];
      this.currentWorker.complianceSupportingFile = null;
      this.handleChange();
    },
    handleChange() {
      this.$emit("onChange", this.currentWorker);
    },
    handleUploadSuccess(file) {
      this.currentWorker.complianceSupportingFile = file;
      this.handleChange();
    },
    async onShow(file) {
      const token = getToken();
      this.fileDialog.src = `${file.url}?token=${token}`;
      this.fileDialog.title = file.fileName;
      this.fileDialog.dialog = true;
    }
  }
};
</script>

<style lang="scss" scoped></style>
