<template>
  <ValidationObserver ref="validation">
    <div class="flex-gap-15 mb-3">
      <ValidationProvider v-slot="{ errors }" name="type" rules="required">
        <Select
          v-model="compliance.documentTypeName"
          label="DBS Check Type"
          placeholder=""
          :items="dbsTypes"
          :error-messages="getErrorMessages(errors)"
          autocomplete
        />
      </ValidationProvider>
      <ValidationProvider
        v-slot="{ errors }"
        name="dbscheckNumber"
        rules="required"
      >
        <v-text-field
          v-model="compliance.documentReferenceNumber"
          @change="handleChange"
          :error-messages="getErrorMessages(errors)"
          label="DBS Check Number"
          placeholder=""
        />
      </ValidationProvider>
      <ValidationProvider
        v-slot="{ errors }"
        name="registeringBody"
        rules="required"
      >
        <v-text-field
          v-model="compliance.registeringBody"
          @change="handleChange"
          :error-messages="getErrorMessages(errors)"
          label="Registering Body"
          placeholder=""
        />
      </ValidationProvider>
      <ValidationProvider
        v-slot="{ errors }"
        name="dateText"
        rules="required|validDate|validDateRange"
      >
        <v-text-field
          type="text"
          placeholder="dd/mm/yyyy-dd/mm/yyyy"
          @change="updateDates"
          v-mask="'##/##/####-##/##/####'"
          format="YYYY-MM-DD"
          v-model="dateText"
          :error-messages="getErrorMessages(errors)"
          label="Issue Date - Expiry Date"
        />
      </ValidationProvider>
      <ValidationProvider
        v-slot="{ errors }"
        name="updateService"
        rules="required"
      >
        <Select
          v-model="compliance.checkHasUpdateService"
          label="Is on DBS Update Service?"
          :items="dbsService"
          :error-messages="getErrorMessages(errors)"
          item-text="label"
          item-value="value"
          autocomplete
        />
      </ValidationProvider>
    </div>
  </ValidationObserver>
</template>

<script>
import Select from "@/components/common/Select";
import { DBS_TYPES, BOOLEAN_OPTIONS } from "@/constants/workers";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import Vue from "vue";
import VueMask from "v-mask";
import { formatDateText, isValidDateRange, splitDateRange } from "@/utils/time";

Vue.use(VueMask);
export default {
  props: {
    /**
     *  Data object
     */
    data: {
      type: Object,
      required: true
    },
    showValidationErrors: Boolean
  },
  components: {
    Select,
    ValidationProvider,
    ValidationObserver
  },
  data() {
    return {
      dbsTypes: DBS_TYPES,
      dbsService: BOOLEAN_OPTIONS,
      compliance: this.data,
      startDate: this.data.documentValidFromDate,
      endDate: this.data.documentValidToDate,
      dateText: `${this.startDate}-${this.endDate}`
    };
  },
  mounted() {
    if (
      this.compliance.documentValidFromDate ||
      this.compliance.documentValidToDate
    ) {
      this.startDate = formatDateText(this.compliance.documentValidFromDate);
      this.endDate = formatDateText(this.compliance.documentValidToDate);
      this.dateText = `${this.startDate}-${this.endDate}`;
    }
  },
  methods: {
    handleChange() {
      this.$emit("onChange", this.compliance);
    },
    getErrorMessages(errors) {
      return this.showValidationErrors ? errors : "";
    },
    updateDates(dates) {
      const { dateCovered, startDate, endDate } = splitDateRange(dates);

      if (isValidDateRange(dateCovered)) {
        this.compliance.documentValidFromDate = startDate;
        this.compliance.documentValidToDate = endDate;
      }
      this.handleChange();
    }
  }
};
</script>

<style lang="scss" scoped></style>
