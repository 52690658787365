<template>
  <ValidationObserver ref="validation">
    <DatePicker
      @onChange="setExpiryDate"
      :label="label"
      :minDate="allowedDate"
      validateName="expiry"
      :input-value="compliance.documentValidToDate"
      :showValidationErrors="showValidationErrors"
      required
      isDark
      class="mb-3"
    />
  </ValidationObserver>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import DatePicker from "@/components/common/DatePicker";
import { formatDate } from "@/utils/time";
import moment from "moment";

export default {
  props: {
    /**
     *  Data object
     */
    data: {
      type: Object,
      required: true
    },
    label: String,
    showValidationErrors: Boolean
  },
  components: {
    ValidationObserver,
    DatePicker
  },
  data() {
    return {
      compliance: this.data
    };
  },
  computed: {
    allowedDate() {
      return moment().format("YYYY-MM-DD");
    }
  },
  methods: {
    setExpiryDate(date) {
      this.compliance.documentValidToDate = formatDate({ date });
      this.$emit("onChange", this.compliance);
    },
    getErrorMessages(errors) {
      return this.showValidationErrors ? errors : "";
    }
  }
};
</script>

<style lang="scss" scoped></style>
