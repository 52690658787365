<template>
  <div class="dialog__form container col-lg-6 col-sm-12">
    <ValidationObserver ref="basicDetailsValidation">
      <BasicDetails
        ref="NewWorkerForm"
        v-show="currentSection('basic')"
        :showValidationErrors="showValidationErrors"
        :currentWorker="currentWorker"
        :permissions="permissions"
        :isUpdate="isUpdate"
        @onChange="handleChange"
      />
    </ValidationObserver>
    <ValidationObserver ref="incomeType">
      <IncomeType
        v-show="currentSection('income')"
        :showValidationErrors="showValidationErrors"
        :isUpdate="isUpdate"
        :currentWorker="currentWorker"
        :permissions="permissions"
        @onChange="handleChange"
      />
    </ValidationObserver>
    <Experience
      v-show="currentSection('experience')"
      :showValidationErrors="showValidationErrors"
      :currentWorker="currentWorker"
      :isUpdate="isUpdate"
      :permissions="permissions"
      @onChange="handleChange"
    />
    <StandardCompliance
      ref="standardCompliance"
      v-show="currentSection('standardCompliance')"
      :showValidationErrors="showValidationErrors"
      :isUpdate="isUpdate"
      :currentWorker="currentWorker"
      :permissions="permissions"
      @onChange="handleChange"
    />
    <AdditionalCompliance
      ref="additionalCompliance"
      v-show="currentSection('additionalCompliance')"
      :showValidationErrors="showValidationErrors"
      :isUpdate="isUpdate"
      :currentWorker="currentWorker"
      :permissions="permissions"
      @onChange="handleChange"
    />
    <SupportingDocument
      v-show="currentSection('documents')"
      :isUpdate="isUpdate"
      :currentWorker="currentWorker"
      :permissions="permissions"
      @onChange="handleChange"
    />
    <ValidationObserver ref="diversity">
      <Diversity
        v-show="currentSection('diversity')"
        :isUpdate="isUpdate"
        :currentWorker="currentWorker"
        :permissions="permissions"
        :showValidationErrors="showValidationErrors"
        @onChange="handleChange"
      />
    </ValidationObserver>
  </div>
</template>

<script>
import BasicDetails from "@/components/workers/BasicDetails";
import IncomeType from "@/components/workers/IncomeType";
import Experience from "@/components/workers/Experience";
import StandardCompliance from "@/components/workers/compliance/StandardCompliance";
import AdditionalCompliance from "@/components/workers/compliance/AdditionalCompliance";
import SupportingDocument from "@/components/workers/compliance/SupportingDocument";
import Diversity from "@/components/workers/Diversity";
import { ValidationObserver } from "vee-validate";
import { getWorkerData, getCompanyData, getAddressData } from "@/utils/workers";
import {
  getFormattedReferences,
  getFormattedComplianceChecks
} from "@/utils/workers";

export default {
  props: {
    /*
     *  If the dialog need steps
     *  Object the contain current and total steps
     * @example
     * { current: 1, total: 10 }
     */
    steps: {
      type: Object,
      required: true
    },
    /*
     * Worker object
     */
    worker: {
      type: Object,
      required: true
    },
    showValidationErrors: Boolean,
    isUpdate: Boolean,
    permissions: Object
  },
  components: {
    BasicDetails,
    IncomeType,
    Experience,
    StandardCompliance,
    AdditionalCompliance,
    SupportingDocument,
    Diversity,
    ValidationObserver
  },
  created() {
    if (this.isUpdate) {
      this.currentWorker = getWorkerData(this.worker);
      this.currentWorker.references = getFormattedReferences(this.worker);
      this.currentWorker.complianceChecks = getFormattedComplianceChecks(
        this.worker
      );
      this.currentWorker.address = getAddressData(this.worker.address);
      this.currentWorker.company = getCompanyData(this.worker.company);
      this.currentWorker.companyAddress = getAddressData(
        this.worker.companyAddress
      );
    }
  },
  data() {
    return {
      currentWorker: getWorkerData(this.worker)
    };
  },
  methods: {
    handleChange(worker) {
      this.$emit("onChange", worker);
    },
    currentSection(section) {
      return this.steps.list[this.steps.current - 1] === section;
    }
  }
};
</script>

<style lang="scss"></style>
