var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h1',{staticClass:"pb-3"},[_vm._v(_vm._s(_vm.formTitle))]),_c('FileUpload',{attrs:{"uploadingUrl":_vm.getAddFileUrl,"fileTypes":[
      '.pdf',
      '.jpeg',
      '.jpg',
      '.png',
      '.doc',
      '.docx',
      '.xlsx',
      '.ppt'
    ],"files":_vm.files,"id":_vm.documentId,"isLoading":_vm.isLoading,"disableAutoProceed":!_vm.isFileUploaded,"disabled":_vm.readonly('workers.edit.cv'),"customActions":""},on:{"onUploadSuccess":_vm.handleUploadSuccess,"removeFile":_vm.onRemoveFile,"unselectFile":_vm.onRemoveFile,"show":_vm.onShow,"selectFile":_vm.selectFile}}),_c('PDFViewFull',{attrs:{"data":_vm.fileDialog}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }